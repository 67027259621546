import RestClient from './rest-client';
import { toaster } from 'evergreen-ui';
import { get } from 'utils/lodash.util';
import { localStorageKey } from 'constants/auth.constant';
import { startPathRedirect } from 'configs/auth.config';

const AUTH_URL = '/auth';

const login = async (payload) => {
  try {
    const response = await RestClient.post(`${AUTH_URL}/login`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const forgotPassword = async (payload) => {
  try {
    const response = await RestClient.post(`${AUTH_URL}/forgot-password`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await RestClient.post(`${AUTH_URL}/reset-password`, payload);
    return response.data;
  } catch (error) {
    typeof window !== 'undefined' && toaster.danger(get(error, ['response', 'data', 'message']));
  }
};

const googleConnection = async (workspaceId) => {
  try {
    const response = await RestClient.get(`/connections/google/url/${workspaceId}`);
    return response.data;
  } catch (error) {
    const errorMess = get(error, ['response', 'data', 'message']);
    typeof window !== 'undefined' && toaster.danger(errorMess);
  }
};

const setLoginBackUrl = (pathName) => {
  localStorage.setItem(localStorageKey.LoginBackUrl, pathName);
};

const getLoginBackUrl = () => {
  const loginBackUrl = localStorage.getItem(localStorageKey.LoginBackUrl);
  return loginBackUrl || startPathRedirect;
};

const AuthService = {
  login,
  forgotPassword,
  resetPassword,
  googleConnection,
  setLoginBackUrl,
  getLoginBackUrl,
};

export default AuthService;
