import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { TextInputField } from 'evergreen-ui';

const FormField = forwardRef(
  (
    {
      label = '',
      type = 'text',
      customClass = '',
      onKeyPress,
      placeholder,
      inputWidth,
      name,
      onChange,
      value,
      checkErrors = [],
      id,
      onFocus,
      onBlur,
      selectOnFocus = false,
      disabled,
      readOnly = false,
      min,
      maxLength,
      autoComplete,
      required,
      refInput = null,
      ...rest
    },
    ref,
  ) => {
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState('');

    const onFocusHandler = (e) => {
      if (selectOnFocus) e.target.select();
      if (onFocus) onFocus();
    };

    const onBlurHandler = () => {
      if (onBlur) onBlur();
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        setTouched(true);
        return validateForm();
      },
    }));

    const onKeyUp = (event) => {
      if (event.charCode === 13) {
        onKeyPress && onKeyPress();
      }
    };

    useEffect(() => {
      validateForm();
    }, [value]);

    const validateForm = () => {
      if (checkErrors.length > 0) {
        for (let idx = 0; idx < checkErrors.length; idx++) {
          const checkErrorFn = checkErrors[idx];
          if (typeof checkErrorFn === 'function') {
            const error = checkErrorFn(value);
            if (error) {
              setError(error);
              return error;
            }
          }
        }
        setError('');
      }
      return '';
    };

    return (
      <TextInputField
        className={`${customClass} !bg-mailstand_gray focus:!bg-white !mb-0`}
        id={id}
        type={type}
        autoComplete={autoComplete}
        value={value}
        disabled={disabled}
        inputWidth={inputWidth}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={refInput}
        label={label}
        isInvalid={touched && !!error}
        validationMessage={touched && !!error ? error : undefined}
        required={required}
        onKeyPress={onKeyUp}
        onChange={onChange}
        onBlur={() => onBlurHandler()}
        onFocus={(e) => onFocusHandler(e)}
        min={min}
        maxLength={maxLength}
        {...rest}
      />
    );
  },
);

export default FormField;
