import { find } from 'utils/lodash.util';

export const isPresence = (value, errorMessage) => {
  if (!value?.trim()) {
    return errorMessage;
  }
  return '';
};

export const checkNumber = (number) => {
  return /^-?\d*$/.test(number);
};

export const isValidEmail = (value, errorMessage = 'Invalid email') => {
  if (
    !!value &&
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
  ) {
    return errorMessage;
  }

  return '';
};

export const clearInvalidVariable = (template) => {
  return (
    template &&
    template
      ?.replaceAll(/float: left;/g, '')
      ?.replaceAll(/float: right;/g, '')
      ?.replaceAll(/display: inline;/g, 'display: block;')
      ?.replaceAll(/margin: 0px 0px 1em 1em;/g, 'margin-left: auto;')
      ?.replaceAll(/margin: 0px 1em 1em 0px;/g, 'margin-right: auto;')
      ?.replaceAll(/<span style="color: rgb\(252, 11, 11\);">(\{\{[^\{]*\}\})<\/span>/gim, `$1`)
  );
};

export const onCheckUnsubscribeURL = (template) => {
  const regex = /unsubscribe/i;
  const regexURL = /{{unsubscribe_url}}/gim;
  const matchRegex = regex.exec(template);
  const matchRegexURL = regexURL.exec(template);

  return matchRegex && !matchRegexURL;
};

export const onCheckExceptVariables = (value) => {
  var rand_spin_regex = new RegExp('#RAND[^]*', 'g');
  const matchRegex = rand_spin_regex.exec(value);
  return !!matchRegex;
};

export const checkInvalidEditorVariable = (variableKeys = [], template) => {
  let currentTemplate = clearInvalidVariable(template);

  const reg = /(\{\{(?:\[??[^\[]*?\}\}))/gim;
  let match = reg.exec(currentTemplate);
  let resultVariables = new Map();
  while (match != null) {
    const str = match[1].slice(0, -2).substring(2);
    resultVariables.set(str, {
      text: str,
      isValid: onCheckExceptVariables(str),
    });
    match = reg.exec(currentTemplate);
  }

  Array.from(resultVariables.keys()).forEach((textItem) => {
    const str = textItem;
    variableKeys.forEach((variable) => {
      const regCheckVariable = new RegExp(`^(${variable})(.*)`, 'g');
      const matchVariable = regCheckVariable.exec(str);
      if (matchVariable) {
        resultVariables.get(textItem).variable = matchVariable[1];
        resultVariables.get(textItem).afterVariable = matchVariable[2];
        const isValid = /^\|([a-zA-z0-9][a-zA-z\s0-9]*[a-zA-z0-9])$/.exec(matchVariable[2]);
        const isValidLink =
          /^\|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.exec(
            matchVariable[2],
          );
        const isValidSpecialChar = /^\|([a-zA-z0-9])$/.exec(matchVariable[2]);
        const isValidSpecialNum = /^\+([a-zA-z0-9])$/.exec(matchVariable[2]);

        if (isValid || isValidLink || isValidSpecialChar || variable === str || isValidSpecialNum) {
          resultVariables.get(textItem).isValid = true;
        }
      }
    });
  });

  let tmp = currentTemplate;
  Array.from(resultVariables.keys()).forEach((item) => {
    if (!resultVariables.get(item).isValid) {
      const textClean = item.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/ /g, ' ');
      const regex = new RegExp(`\{\{(${textClean})\}\}`, 'g');
      tmp = tmp.replaceAll(regex, `<span style="color: rgb(252, 11, 11);">{{$1}}</span>`);
    }
  });

  const isInvalidSignature = !!find(Array.from(resultVariables.keys()), (item) => {
    const regex = /signature\|/gim;
    const matchRegex = regex.exec(resultVariables.get(item).text);
    if (!resultVariables.get(item).isValid && matchRegex) {
      return item;
    }
    return null;
  });

  const isInvalid = !!find(
    Array.from(resultVariables.keys()),
    (item) => !resultVariables.get(item).isValid,
  );

  return {
    templateValue: tmp,
    isInvalid,
    isInvalidSignature,
  };
};
