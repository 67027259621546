import { useState, createRef, useContext, useEffect } from 'react';
import Image from 'next/image';
import Head from 'next/head';
import Link from 'next/link';
import Router from 'next/router';
import Cookie from 'js-cookie';
import { checkAuth, startPathRedirect } from 'configs/auth.config';
import { AUTH_KEY } from 'constants/auth.constant';
import { AppContext } from 'contexts/app.context';
import { Button } from 'evergreen-ui';
import { get } from 'utils/lodash.util';
import { isPresence, isValidEmail } from 'utils/validators.util';
import AuthService from 'services/auth.service';
import FormField from 'components/Form/FormField';

function Login() {
  const { user, isLoggedIn } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const isVerify = get(user, ['views', 'verified_email']);
  const emailRef = createRef();
  const passwordRef = createRef();

  const isFormValid = () => {
    return !emailRef.current?.validate() || !passwordRef.current?.validate();
  };

  const onChangeFormField = (e, fieldName) => {
    setForm({
      ...form,
      [fieldName]: e.target.value,
    });
  };

  const onSubmit = () => {
    if (isFormValid() && !loading) {
      setLoading(true);
      AuthService.login({
        ...form,
      })
        .then((resp) => {
          if (resp) {
            Cookie.set(AUTH_KEY, resp.api_key, { expires: 60 });
            Router.push(AuthService.getLoginBackUrl());
          } else {
            setForm({
              ...form,
              password: '',
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (user && !isVerify && isLoggedIn) {
      Router.push('/verify');
    }
  }, [user]);

  return (
    <div className="bg-mailstand_gray h-full min-h-screen">
      <Head>
        <title>Log In | Mailstand</title>
      </Head>
      <div className="max-w-sm mx-auto pt-12 md:pt-20 px-4 md:px-0">
        <div className="text-center">
          <Image src="/images/logo.svg" width={30} height={25} alt="logo" />
          <h2 className="font-semibold text-mailstand_neutral-n900 text-base pt-2 pb-3">
            Login to Mailstand
          </h2>
        </div>
        <div className="py-6 px-8 bg-white border rounded-[3px]">
          <FormField
            id="email"
            label="Email"
            onKeyPress={onSubmit}
            ref={emailRef}
            onChange={(e) => onChangeFormField(e, 'email')}
            value={form.email}
            checkErrors={[
              (val) => isPresence(val, 'This field is required'),
              (val) => isValidEmail(val, 'Please enter a valid email'),
            ]}
          />
          <div className="relative">
            <Link href="/forgot-password">
              <a className="text-xs text-mailstand_blue absolute right-0">Forgot password?</a>
            </Link>
          </div>
          <FormField
            id="password"
            type="password"
            ref={passwordRef}
            autoComplete="new-password"
            label="Password"
            onKeyPress={onSubmit}
            onChange={(e) => onChangeFormField(e, 'password')}
            value={form.password}
            checkErrors={[(val) => isPresence(val, 'This field is required')]}
          />
          <Button
            isLoading={loading}
            onClick={onSubmit}
            className="w-full !bg-mailstand_neutral hover:text-mailstand_neutral-n800 !font-bold !text-white btn-black">
            Sign in
          </Button>
        </div>
        <div className="p-5 bg-white border mt-4 text-sm text-center rounded-[3px]">
          <span className="text-mailstand_neutral-n800">Don’t have an account yet?</span>
          <Link href="/signup">
            <a className="text-mailstand_blue"> Sign up free</a>
          </Link>
          .
        </div>
      </div>
    </div>
  );
}

export async function getServerSideProps(context) {
  const { req, res } = context;
  const token = checkAuth(context);
  if (token) {
    return {
      redirect: {
        destination: startPathRedirect,
        permanent: false,
      },
    };
  }

  if (req?.headers?.host.match(/^www/) === null && process.env.NODE_ENV !== 'development') {
    return {
      redirect: {
        destination: 'https://www.' + req.headers.host + req.url,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}

export default Login;
