import Router from 'next/router';
import { parseCookies } from 'utils/parseCookies.util';
import cookie from 'js-cookie';
import { AUTH_KEY } from 'constants/auth.constant';
import AuthService from 'services/auth.service';

export const hthauPathRedirect = '/hthau'
export const startPathRedirect = '/';
export const customDomainName = process.env.CUSTOM_DOMAIN_NAME;

export const checkAuth = (ctx) => {
  const { req } = ctx;
  const cookies = parseCookies(req);
  const accessToken = cookies && cookies[AUTH_KEY];
  return accessToken;
};

export const logout = () => {
  cookie.remove('token');
  // to support logging out from all windows
  window.localStorage.setItem('logout', Date.now());
  Router.push('/login');
};

export const ProtectRoute = (WrappedComponent, redirectPath) => {
  return (props) => {
    if (typeof window !== 'undefined') {
      const accessToken = cookie.get(AUTH_KEY);
      if (!accessToken) {
        Router.push('/login');
        AuthService.setLoginBackUrl(redirectPath || location.pathname);
      }
    }
    return <WrappedComponent {...props} />;
  };
};

export const configUploadCSVdS3 = {
  bucketName: process.env.NEXT_APP_BUCKET_UPLOADS_NAME,
  region: process.env.NEXT_APP_REGION,
  accessKeyId: process.env.NEXT_APP_ACCESS_ID,
  secretAccessKey: process.env.NEXT_APP_ACCESS_KEY,
};

export const configMailstandFilesS3 = {
  bucketName: process.env.NEXT_APP_BUCKET_MALSTAND_NAME,
  region: process.env.NEXT_APP_REGION,
  accessKeyId: process.env.NEXT_APP_ACCESS_ID,
  secretAccessKey: process.env.NEXT_APP_ACCESS_KEY,
};
